<template>
    <div>
        <div class="top-header blackBG border-bottom">
            <div class="marquee border-top-0 border-bottom">
                <bannerslider />
            </div>
        </div>
        <!-- <section class="blockElement ideaMenu secondaryBG border-bottom multiMenu">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <bottomnavbar/>
                    </div>
                </div>
            </div>
        </section> -->
        <section class="bg-white blockElement innerPage-banner">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <div class="breadCrum d-flex align-items-center justify-content-between flex-wrap">
                            <div class="bdPage">
                                <h2>The Gross Domestic Product - <span class="green">India</span></h2>
                                <nav aria-label="breadcrumb mt-2">
                                    <ol class="breadcrumb mb-0">
                                        <li class="breadcrumb-item">
                                            <router-link class="green" to="/dashboard">Home</router-link>
                                        </li>
                                        <li class="breadcrumb-item">
                                            <router-link class="green" to="/economic-overview"> Economic Overview
                                            </router-link>
                                        </li>
                                        <li class="breadcrumb-item active" aria-current="page">GDP India</li>
                                    </ol>
                                </nav>
                            </div>
                            <div class="rightSearch">
                                <router-link to="/economy-compare" class="link d-flex align-items-center"><img
                                        width="20" src="assets/images/round-balance.svg" alt="icon" title="Grid"
                                        class="me-1"><span class="dark-org-btn-small text-white px-2">Compare</span>
                                    <span class="green mx-1"> GDP </span> with other countries
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- <section class="bg-white blockElement grossDomestic">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <ul class="toplinks d-flex align-items-center mb-3 pb-3 border-bottom">
                            <li class="ps-0"><a  @click.prevent="grossTab = 'Forecast'" :class="(grossTab == 'Forecast')?'active':''" href="javascript:void(0)">Forecast</a></li>
                            <li><a @click.prevent="grossTab = 'Summary'" :class="(grossTab == 'Summary')?'active':''" href="javascript:void(0)">Summary</a></li>
                        </ul>
                        
                    </div>
                </div>
            </div>
        </section> -->
        <div class="showTabb" v-if="grossTab == 'Forecast'">
            <section class="bg-white blockElement">
                <div class="container">
                    <div class="row justify-content-between">
                        <div class="col-12">
                            <h5 class="medium mb-3">Summary</h5>
                        </div>
                        <div class="col-12 col-xl-8 mb-4 mb-xl-0">
                            <div class="compareChart trade border">
                                <div class="content p-3 border-bottom trade">
                                    <p class="mb-0">The gross domestic product (GDP) measures of national income and
                                        output for a given country's economy. The gross domestic product (GDP) is equal
                                        to the total expenditures for all final goods and services produced within the
                                        country in a stipulated period of time.</p>
                                </div>
                                <div
                                    class="chartPattern visible d-flex align-items-center justify-content-between mt-md-4 flex-wrap">
                                    <ul class="chartBootm d-flex align-items-center justify-content-between mb-0">
                                        <li class="medium">
                                            <p class="mb-1 lighttext f-14">Actual</p>
                                            <p class="mb-0 f-18 ">0.80</p>
                                        </li>
                                        <li class="medium">
                                            <p class="mb-1 lighttext f-14">Previous</p>
                                            <p class="mb-0 f-18">1.70</p>
                                        </li>
                                        <li class="medium">
                                            <p class="mb-1 lighttext f-14">Highest</p>
                                            <p class="mb-0 f-18 themecolor">24.80</p>
                                        </li>
                                        <li class="medium">
                                            <p class="mb-1 lighttext f-14">Lowest</p>
                                            <p class="mb-0 f-18 red">-24.20</p>
                                        </li>

                                    </ul>
                                    <div class="d-flex align-items-center mobFull">
                                        <ul class="chartTimeline d-flex align-items-center justify-content-end mb-0">
                                            <li v-if="showChart == 'Bar'"><a href="javascript:void(0)" @click="showChart = 'Line';drawLineChart()"><img width="12"
                                                        src="assets/images/trade.svg" alt="Icon" title="Trade"></a></li>
                                            <li v-if="showChart == 'Line'"><a href="javascript:void(0)" @click="showChart = 'Bar';drawBarChart()"><img width="12"
                                                        src="assets/images/calendar.svg" alt="Icon" title="Trade"></a>
                                            </li>
                                        </ul>
                                        <div class="position-relative">
                                            <a @click.prevent="dropdownMenu = !dropdownMenu" href="javascript:void(0)"
                                                class="megaDrop border-btn d-flex align-items-center justify-content-between d-lg-none mx-2">15M
                                                <i class="ms-3 fa fa-sort-desc" aria-hidden="true"></i></a>
                                            <ul class="dropdown_menu_animated onMbile chartTimeline d-flex align-items-center justify-content-end mb-0"
                                                :class="dropdownMenu ? 'show' : ''">
                                                <li><a href="javascript:void(0)" @click="filteryear = '5';callCountryEconomyData($route.query.country);showChart = 'Bar'">5Y</a></li>
                                                <li><a href="javascript:void(0)" @click="filteryear = '10';callCountryEconomyData($route.query.country);;showChart = 'Bar'">10Y</a></li>
                                                <li><a href="javascript:void(0)" @click="filteryear = '25';callCountryEconomyData($route.query.country);showChart = 'Line'">25Y</a></li>
                                                <li><a href="javascript:void(0)" @click="filteryear = '';callCountryEconomyData($route.query.country);showChart = 'Line'">MAX</a></li>
                                            </ul>
                                        </div>
                                        <ul class="chartTimeline d-flex align-items-center justify-content-end mb-0">
                                            <li class="position-relative viewCompare">
                                                <a @click.prevent="Compare = !Compare;countryIndicator = false,compareCountries = []" href="javascript:void(0)"
                                                    class="dark-org-btn-small text-white me-2 d-flex align-items-center">
                                                    <vue-feather size="15px" class="me-1" type="plus-square">
                                                    </vue-feather> Compare
                                                </a>
                                                <div class="dropdown_menu_animated compareIndicatorpopup"
                                                    :class="Compare ? 'show' : ''">
                                                    <div
                                                        class="content-header p-3 flex-wrap border-bottom trade d-flex align-items-center">
                                                        <div class="d-flex align-items-center">
                                                            <h6 class="medium f-18 mb-0">Comapre</h6>
                                                            <span class="status greenBG mx-3">{{$route.query.country}}</span>
                                                            <span class="status greenBG">GDP</span>
                                                        </div>
                                                        <div class="d-flex align-items-center slideBrq">
                                                            <p class="mb-0 ms-md-3">with: <span
                                                                    :class="countryIndicator ? 'green' : ''">Indicators</span>
                                                            </p>
                                                            <label class="toggleSwitch d-flex mx-2">
                                                                <input type="checkbox" v-model="countryIndicator">
                                                                <span class="slider round"></span>
                                                            </label>
                                                            <p class="mb-0" :class="countryIndicator ? '' : 'green'">
                                                                Countries</p>
                                                        </div>
                                                    </div>
                                                    <!-- Comapre Indicators -->
                                                    <div class="content border-bottom trade px-3 py-4"
                                                        :class="countryIndicator ? 'd-none' : ''">
                                                        <div class="d-flex align-items-center flex-wrap">
                                                            <div class="form-group mb-md-0 me-md-3">
                                                                <select
                                                                    class="form-control form-select custom bodyBG disabled">
                                                                    <option>India</option>
                                                                    <option>United States</option>
                                                                    <option>Germany</option>
                                                                    <option>France</option>
                                                                    <option>Brazil</option>
                                                                </select>
                                                            </div>
                                                            <div class="multiSelect position-relative bodyBG">
                                                                <a @click.prevent="CompareDrop = !CompareDrop"
                                                                    href="javascript:void(0)"
                                                                    class="multiView d-flex align-items-center justify-content-between border trade">
                                                                    <span class="d-flex align-items-center selected" v-if="compareIndicators.length">
                                                                        <span class="status" v-for="item,index in compareIndicators.slice(0,2)">
                                                                            {{item}}<a href="javascript:void(0)"
                                                                                class="closeX p-0 ms-1 d-flex" @click="removeIndicator(item)">
                                                                                <vue-feather size="15" type="x">
                                                                                </vue-feather>
                                                                            </a>
                                                                        </span>
                                                                        <!-- <span class="status">
                                                                            Intrest rate <a href="javascript:void(0)"
                                                                                class="closeX p-0 ms-1 d-flex">
                                                                                <vue-feather size="15" type="x">
                                                                                </vue-feather>
                                                                            </a>
                                                                        </span> -->
                                                                        <span class="status" v-if="compareIndicators.length > 2">
                                                                            {{compareIndicators.length-2}} more <a href="javascript:void(0)"
                                                                                class="closeX p-0 ms-1 d-flex"></a>
                                                                        </span>
                                                                    </span>
                                                                    <vue-feather type="chevron-down" size="20px">
                                                                    </vue-feather>
                                                                </a>
                                                                <div class="dropdown_menu_animated w-100"
                                                                    :class="CompareDrop ? 'show' : ''">
                                                                    <label class="customCheckbox mb-0" v-for="value,key in static_vars.economyIndicatorsWithoutGDP" :key="value">{{key}}
                                                                        <input type="checkbox" :name="key" v-model="compareIndicators" :value="value">
                                                                        <span class="checkmark"></span>
                                                                    </label>
                                                                    <!-- <label class="customCheckbox mb-0">Intrest rate
                                                                        <input type="checkbox" name="">
                                                                        <span class="checkmark"></span>
                                                                    </label> -->
                                                                </div>
                                                            </div>
                                                            <a href="javascript:void(0)" class="closeX custom">
                                                                <vue-feather size="25" type="x"></vue-feather>
                                                            </a>
                                                        </div>
                                                    </div>
                                                    <!-- End -->
                                                    <!-- Comapre Countries -->
                                                    <div class="content border-bottom trade px-3 py-4"
                                                        :class="countryIndicator ? '' : 'd-none'">
                                                        <div class="d-flex align-items-center flex-wrap mb-3" v-for="item ,index in compareCountries" :key="index">
                                                            <div class="form-group mb-md-0 me-md-3">
                                                                <v-select 
                                                                :options="allCountries" 
                                                                 v-model="item.country"
                                                               
                                                              ></v-select>
                                                            </div>
                                                            <div class="multiSelect position-relative bodyBG">
                                                                <a @click.prevent="CompareDrop1 = !CompareDrop1"
                                                                    href="javascript:void(0)"
                                                                    class="multiView border trade">
                                                                    <span class="d-flex align-items-center selected">
                                                                        <span class="status">
                                                                            GDP <a href="javascript:void(0)"
                                                                                class="closeX p-0 ms-1 d-flex">
                                                                                <vue-feather size="15" type="x">
                                                                                </vue-feather>
                                                                            </a>
                                                                        </span>
                                                                    </span>
                                                                    <!-- <vue-feather type="chevron-down" size="20px"></vue-feather> -->
                                                                </a>
                                                                <!-- <div class="dropdown_menu_animated w-100" :class="CompareDrop1?'show':''">
                                                                    <label class="customCheckbox mb-0">Inflation
                                                                        <input type="checkbox" name="">
                                                                        <span class="checkmark"></span>
                                                                    </label>
                                                                    <label class="customCheckbox mb-0">Intrest rate
                                                                        <input type="checkbox" name="">
                                                                        <span class="checkmark"></span>
                                                                    </label>
                                                                </div> -->
                                                            </div>
                                                            <a href="javascript:void(0)" class="closeX custom" @click="removeCountry(index)">
                                                                <vue-feather size="25" type="x"></vue-feather>
                                                            </a>
                                                        </div>
                                                        <!-- div class="d-flex align-items-center flex-wrap mb-3">
                                                            <div class="form-group mb-md-0 me-md-3">
                                                                <v-select 
                                                                :options="allCountries" 
                                                                v-model="compareCountries"
                                                              ></v-select>
                                                            </div>

                                                            <div class="multiSelect position-relative bodyBG">
                                                                <a @click.prevent="CompareDrop = !CompareDrop"
                                                                    href="javascript:void(0)"
                                                                    class="multiView border trade">
                                                                    <span class="d-flex align-items-center selected">
                                                                        <span class="status">
                                                                            GDP <a href="javascript:void(0)"
                                                                                class="closeX p-0 ms-1 d-flex">
                                                                                <vue-feather size="15" type="x">
                                                                                </vue-feather>
                                                                            </a>
                                                                        </span>
                                                                    </span>
                                                                    
                                                                </a>
                                                               
                                                            </div>
                                                            <a href="javascript:void(0)" class="closeX custom">
                                                                <vue-feather size="25" type="x"></vue-feather>
                                                            </a>
                                                        </div> -->
                                                        <div class="addAnothre">
                                                            <a class="d-inline-flex align-items-center ps-0"
                                                                href="javascript:void(0)" @click="addCountry()">
                                                                <vue-feather size="16" class="me-1" type="plus-circle">
                                                                </vue-feather> Add another country
                                                            </a>
                                                        </div>
                                                    </div>
                                                    <div class="footerBtn text-end py-md-4 py-3 px-3">
                                                        <a @click.prevent="checkIndicators()" href="javascript:void(0)"
                                                            class="grd_btn d-inline-flex">Compare</a>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="viewCompareChart" v-show="taskTab == 0">
                                    <!-- <img src="assets/images/chart/summaryChart.svg" alt="Chart" title="India GDP"> -->
                                    <div id="barChart" style="width: 100%; height: 400px"></div>
                                </div>
                                <div class="viewCompareChart text-end" v-show="taskTab == 1">
                                    <a href="javascript:void(0)"
                                        class="text-white closex d-inline-flex align-items-center greenBG status"
                                        @click="taskTab = 0">Comparison <vue-feather type="x" size="13" class="ms-1">
                                        </vue-feather> </a>
                                    <!-- <img src="assets/images/chart/compare.svg" alt="Chart" title="India GDP"> -->
                                      <div id="compareIndicator" style="width: 100%; height: 400px"></div>
                                </div>
                                <div class="viewCompareChart text-end" v-show="taskTab == 2">
                                    <a href="javascript:void(0)"
                                        class="text-white closex d-inline-flex align-items-center greenBG status"
                                        @click="taskTab = 0">Comparison <vue-feather type="x" size="13" class="ms-1">
                                        </vue-feather> </a>
                                    <!-- <img src="assets/images/chart/compare1.svg" class="w-100" alt="Chart"
                                        title="India GDP"> -->
                                        <div id="compareCountry" style="width: 100%; height: 400px"></div>
                                </div>
                            </div>
                            <div class="summaryContent elementSpace">
                                <h5 class="medium mb-3">Summary</h5>
                                <p>In India, The growth rate in GDP Measures the change in the seasonally adjusted value
                                    of the goods and
                                    ervices produced by the India economy during the quarter. India is the world's tenth
                                    largest economy and
                                    he most populous. The most important and the fastest growing sector of indian
                                    economy are services.
                                    rade, hotels transport and communication, financing, insurance, real estate and
                                    business services and community,
                                    ocial and personal services account for more than 60 percent of GDP. Agriculture,
                                    forestry and fishing constitute
                                    round 12 percent of the output, but employs more than 50 percent of the labor force.
                                    Manufacturing accounts for 15
                                    ercent of GDP, construction fot another 8 percent and mining quarring, electricity,
                                    gas and water supply for the remaning 5 percent</p>
                            </div>
                            <carousel :settings="settings" :breakpoints="breakpoints"
                                class="owl-carousel owl-theme relatedIndicators">
                                <slide key="1" class="card-body p-0 d-block">
                                    <div class="content">
                                        <a href="javascript:void(0)"
                                            class="d-flex align-items-center justify-content-between">
                                            <span class="d-flex align-items-start">
                                                <img width="12" class="mt-1 me-2" src="assets/images/up.svg" alt="Icon"
                                                    title="Up">
                                                <span class="flex-grow-1">
                                                    <p class="f-20 mb-2">NIFTY 50</p>
                                                    <p class="f-18 green mb-0">+238.00</p>
                                                </span>
                                            </span>
                                            <span class="text-end">
                                                <p class="f-20 mb-2 medium">17,150.25</p>
                                                <p class="f-18 green mb-0">1.41%</p>
                                            </span>
                                        </a>
                                    </div>
                                    <div class="valueChart">
                                        <img class="d-block" src="assets/images/chart/tradeChart.svg" alt="Chart"
                                            title="GDP Growth Rate">
                                    </div>
                                </slide>
                                <slide :key="2" class="card-body p-0 d-block">
                                    <div class="content">
                                        <a href="javascript:void(0)"
                                            class="d-flex align-items-center justify-content-between">
                                            <span class="d-flex align-items-start">
                                                <img width="12" class="mt-1 me-2" src="assets/images/up.svg" alt="Icon"
                                                    title="Up">
                                                <span class="flex-grow-1">
                                                    <p class="f-20 mb-2">NIFTY 50</p>
                                                    <p class="f-18 green mb-0">+238.00</p>
                                                </span>
                                            </span>
                                            <span class="text-end">
                                                <p class="f-20 mb-2 medium">17,150.25</p>
                                                <p class="f-18 green mb-0">1.41%</p>
                                            </span>
                                        </a>
                                    </div>
                                    <div class="valueChart">
                                        <img class="d-block" src="assets/images/chart/tradeChart.svg" alt="Chart"
                                            title="GDP Growth Rate">
                                    </div>
                                </slide>
                                <slide :key="3" class="card-body p-0 d-block">
                                    <div class="content">
                                        <a href="javascript:void(0)"
                                            class="d-flex align-items-center justify-content-between">
                                            <span class="d-flex align-items-start">
                                                <img width="12" class="mt-1 me-2" src="assets/images/up.svg" alt="Icon"
                                                    title="Up">
                                                <span class="flex-grow-1">
                                                    <p class="f-20 mb-2">NIFTY 50</p>
                                                    <p class="f-18 green mb-0">+238.00</p>
                                                </span>
                                            </span>
                                            <span class="text-end">
                                                <p class="f-20 mb-2 medium">17,150.25</p>
                                                <p class="f-18 green mb-0">1.41%</p>
                                            </span>
                                        </a>
                                    </div>
                                    <div class="valueChart">
                                        <img class="d-block" src="assets/images/chart/tradeChart.svg" alt="Chart"
                                            title="GDP Growth Rate">
                                    </div>
                                </slide>
                                <slide :key="4" class="card-body p-0 d-block">
                                    <div class="content">
                                        <a href="javascript:void(0)"
                                            class="d-flex align-items-center justify-content-between">
                                            <span class="d-flex align-items-start">
                                                <img width="12" class="mt-1 me-2" src="assets/images/up.svg" alt="Icon"
                                                    title="Up">
                                                <span class="flex-grow-1">
                                                    <p class="f-20 mb-2">NIFTY 50</p>
                                                    <p class="f-18 green mb-0">+238.00</p>
                                                </span>
                                            </span>
                                            <span class="text-end">
                                                <p class="f-20 mb-2 medium">17,150.25</p>
                                                <p class="f-18 green mb-0">1.41%</p>
                                            </span>
                                        </a>
                                    </div>
                                    <div class="valueChart">
                                        <img class="d-block" src="assets/images/chart/tradeChart.svg" alt="Chart"
                                            title="GDP Growth Rate">
                                    </div>
                                </slide>
                                <slide :key="5" class="card-body p-0 d-block">
                                    <div class="content">
                                        <a href="javascript:void(0)"
                                            class="d-flex align-items-center justify-content-between">
                                            <span class="d-flex align-items-start">
                                                <img width="12" class="mt-1 me-2" src="assets/images/up.svg" alt="Icon"
                                                    title="Up">
                                                <span class="flex-grow-1">
                                                    <p class="f-20 mb-2">NIFTY 50</p>
                                                    <p class="f-18 green mb-0">+238.00</p>
                                                </span>
                                            </span>
                                            <span class="text-end">
                                                <p class="f-20 mb-2 medium">17,150.25</p>
                                                <p class="f-18 green mb-0">1.41%</p>
                                            </span>
                                        </a>
                                    </div>
                                    <div class="valueChart">
                                        <img class="d-block" src="assets/images/chart/tradeChart.svg" alt="Chart"
                                            title="GDP Growth Rate">
                                    </div>
                                </slide>
                                <template #addons>
                                    <navigation />
                                </template>
                            </carousel>
                            <div class="relatedNews elementSpace">
                                <h5 class="mb-4 medium">Related News</h5>
                                <div class="row">
                                    <div class="col-12 col-lg-6 mb-4">
                                        <div class="card-body p-0">
                                            <h6><a href="javascript:void(0)">US 10Y yields point to some
                                                    consolidation
                                                    around 1.28%...</a></h6>
                                            <p class="d-flex align-items-center f-12 lighttext mb-1">
                                                <vue-feather size="14px" class="me-1" type="clock"></vue-feather> 7
                                                hours ago
                                            </p>
                                            <p class="mb-2">The US dollar is trading in a narrow range ahead of the
                                                event, typical of such days.</p>
                                        </div>
                                    </div>
                                    <div class="col-12 col-lg-6 mb-4">
                                        <div class="card-body p-0">
                                            <h6><a href="javascript:void(0)">US 10Y yields point to some
                                                    consolidation
                                                    around 1.28%...</a></h6>
                                            <p class="d-flex align-items-center f-12 lighttext mb-1">
                                                <vue-feather size="14px" class="me-1" type="clock"></vue-feather> 7
                                                hours ago
                                            </p>
                                            <p class="mb-2">The US dollar is trading in a narrow range ahead of the
                                                event, typical of such days.</p>
                                        </div>
                                    </div>
                                    <div class="col-12 col-lg-6 mb-4">
                                        <div class="card-body p-0">
                                            <h6><a href="javascript:void(0)">US 10Y yields point to some
                                                    consolidation
                                                    around 1.28%...</a></h6>
                                            <p class="d-flex align-items-center f-12 lighttext mb-1">
                                                <vue-feather size="14px" class="me-1" type="clock"></vue-feather> 7
                                                hours ago
                                            </p>
                                            <p class="mb-2">The US dollar is trading in a narrow range ahead of the
                                                event, typical of such days.</p>
                                        </div>
                                    </div>
                                    <div class="col-12 col-lg-6">
                                        <div class="card-body  p-0">
                                            <h6><a href="javascript:void(0)">US 10Y yields point to some
                                                    consolidation
                                                    around 1.28%...</a></h6>
                                            <p class="d-flex align-items-center f-12 lighttext mb-1">
                                                <vue-feather size="14px" class="me-1" type="clock"></vue-feather> 7
                                                hours ago
                                            </p>
                                            <p class="mb-2">The US dollar is trading in a narrow range ahead of the
                                                event, typical of such days.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-xl-4">
                            <div class="compareChart trade border text-center p-4">
                                <a @click="summery = !summery" href="javascript:void(0)"
                                    class="dark-org-btn-small text-white px-2 position-absolute">Forecast</a>
                                <div class="showSummary" :class="summery ? 'd-none' : 'd-block'">
                                    <div class="headTitle">
                                        <h5 class="medium"><span class="green">India:</span> GDP Composition
                                        </h5>
                                        <!-- <h3 class="green medium">$ 2868.93</h3> -->
                                    </div>
                                    <div id="compChart" style="width: 100%; height: 340px"></div>
                                    <ul class="greenDot d-flex align-items-center justify-content-between mb-0" v-if="chartData.length">
                                        <li v-for="item,index in chartData.slice(0,3)"><span class="green">{{item.percent}}% </span>{{item.sector}}</li>
                                        <!-- <li><span class="green">35%</span> Agriculture</li>
                                        <li><span class="green">40%</span> Construction</li> -->
                                    </ul>
                                </div>
                                <div class="showForcast" :class="summery ? 'd-block' : 'd-none'">
                                    <div class="compareChart trade">
                                        <h6 class="f-17 p-0 mb-3">Share your <span class="green">Thoughts</span></h6>
                                        <div
                                            class="d-flex align-items-center justify-content-between flex-wrap mb-2 p-0 mb-4">
                                            <label class="customCheckbox w-100 mb-4">Vote Impact on GBP
                                                <input type="checkbox">
                                                <span class="checkmark"></span>
                                            </label>
                                            <label class="customCheckbox neutral active">Positive
                                                <input type="radio" name="radio">
                                                <span class="checkmark"></span>
                                                <span class="selectStar">
                                                    <vue-feather type="star" size="20px"></vue-feather>
                                                </span>
                                            </label>
                                            <label class="customCheckbox neutral">Negative
                                                <input type="radio" name="radio">
                                                <span class="checkmark"></span>
                                                <span class="selectStar">
                                                    <vue-feather type="star" size="20px"></vue-feather>
                                                </span>
                                            </label>
                                            <label class="customCheckbox neutral">Neutral
                                                <input type="radio" name="radio">
                                                <span class="checkmark"></span>
                                                <span class="selectStar">
                                                    <vue-feather type="star" size="20px"></vue-feather>
                                                </span>
                                            </label>
                                        </div>
                                        <div class="p-0 mb-4">
                                            <label class="customCheckbox w-100 mb-3">Mark your expectations
                                                <input type="checkbox">
                                                <span class="checkmark"></span>
                                            </label>
                                            <div class="range-slider">
                                                <input class="range-slider__range" type="range" value="100" min="0"
                                                    max="500">
                                                <div class="d-flex align-items-center justify-content-between">
                                                    <span class="range-slider__value">100</span>
                                                    <span class="slider__value green">120</span>
                                                </div>
                                            </div>
                                        </div>
                                        <a @click.prevent="submitVote = !submitVote" href="javascript:void(0)"
                                            class="grd_btn">Submit your vote</a>
                                    </div>
                                </div>
                            </div>
                            <div class="otherIndicator pt-md-5 pt-3">
                                <div class="border-bottom trade pb-3 mb-4">
                                    <h4>Other Indicators</h4>
                                </div>
                                <div class="accordianList">
                                    <h6 @click.prevent="indicatorsTabs = '1'"
                                        :class="(indicatorsTabs == '1') ? 'active' : ''"
                                        class="pointer d-flex align-items-center justify-content-between">
                                        <span class="rounded-circle"><img src="assets/images/global.svg" alt="global"
                                                title="GDP"></span>
                                        <span class="d-flex align-items-center justify-content-between clickView"> GDP
                                            <vue-feather size="18px" type="chevron-down"></vue-feather></span>
                                    </h6>
                                    <ul class="openAccordian" :class="(indicatorsTabs == '1') ? 'show' : ''">
                                        <li><a class="active" href="javascript:void(0)">GDP</a></li>
                                        <li><a href="javascript:void(0)">GNP</a></li>
                                        <li><a href="javascript:void(0)">GDP Constant Rate </a></li>
                                        <li><a href="javascript:void(0)">GDP Fixed Capital Formation</a></li>
                                    </ul>
                                </div>
                                <div class="accordianList">
                                    <h6 @click.prevent="indicatorsTabs = '2'"
                                        :class="(indicatorsTabs == '2') ? 'active' : ''"
                                        class="pointer d-flex align-items-center justify-content-between">
                                        <span class="rounded-circle"><img src="assets/images/company.svg" alt="global"
                                                title="GDP"></span>
                                        <span class="d-flex align-items-center justify-content-between clickView">
                                            Markets <vue-feather size="18px" type="chevron-down"></vue-feather></span>
                                    </h6>
                                    <ul class="openAccordian" :class="(indicatorsTabs == '2') ? 'show' : ''">
                                        <li><a class="active" href="javascript:void(0)">GDP</a></li>
                                        <li><a href="javascript:void(0)">GNP</a></li>
                                        <li><a href="javascript:void(0)">GDP Constant Rate </a></li>
                                        <li><a href="javascript:void(0)">GDP Fixed Capital Formation</a></li>
                                    </ul>
                                </div>
                                <div class="accordianList">
                                    <h6 @click.prevent="indicatorsTabs = '3'"
                                        :class="(indicatorsTabs == '3') ? 'active' : ''"
                                        class="pointer d-flex align-items-center justify-content-between">
                                        <span class="rounded-circle"><img src="assets/images/labour.svg" alt="global"
                                                title="GDP"></span>
                                        <span class="d-flex align-items-center justify-content-between clickView">Labour
                                            <vue-feather size="18px" type="chevron-down"></vue-feather></span>
                                    </h6>
                                    <ul class="openAccordian" :class="(indicatorsTabs == '3') ? 'show' : ''">
                                        <li><a class="active" href="javascript:void(0)">GDP</a></li>
                                        <li><a href="javascript:void(0)">GNP</a></li>
                                        <li><a href="javascript:void(0)">GDP Constant Rate </a></li>
                                        <li><a href="javascript:void(0)">GDP Fixed Capital Formation</a></li>
                                    </ul>
                                </div>
                                <div class="accordianList">
                                    <h6 @click.prevent="indicatorsTabs = '4'"
                                        :class="(indicatorsTabs == '4') ? 'active' : ''"
                                        class="pointer d-flex align-items-center justify-content-between">
                                        <span class="rounded-circle"><img src="assets/images/price.svg" alt="global"
                                                title="GDP"></span>
                                        <span class="d-flex align-items-center justify-content-between clickView">Prices
                                            <vue-feather size="18px" type="chevron-down"></vue-feather></span>
                                    </h6>
                                    <ul class="openAccordian" :class="(indicatorsTabs == '4') ? 'show' : ''">
                                        <li><a class="active" href="javascript:void(0)">GDP</a></li>
                                        <li><a href="javascript:void(0)">GNP</a></li>
                                        <li><a href="javascript:void(0)">GDP Constant Rate </a></li>
                                        <li><a href="javascript:void(0)">GDP Fixed Capital Formation</a></li>
                                    </ul>
                                </div>
                                <div class="accordianList">
                                    <h6 @click.prevent="indicatorsTabs = '5'"
                                        :class="(indicatorsTabs == '5') ? 'active' : ''"
                                        class="pointer d-flex align-items-center justify-content-between">
                                        <span class="rounded-circle"><img src="assets/images/health.svg" alt="global"
                                                title="GDP"></span>
                                        <span class="d-flex align-items-center justify-content-between clickView">Health
                                            <vue-feather size="18px" type="chevron-down"></vue-feather></span>
                                    </h6>
                                    <ul class="openAccordian" :class="(indicatorsTabs == '5') ? 'show' : ''">
                                        <li><a class="active" href="javascript:void(0)">GDP</a></li>
                                        <li><a href="javascript:void(0)">GNP</a></li>
                                        <li><a href="javascript:void(0)">GDP Constant Rate </a></li>
                                        <li><a href="javascript:void(0)">GDP Fixed Capital Formation</a></li>
                                    </ul>
                                </div>
                                <div class="accordianList">
                                    <h6 @click.prevent="indicatorsTabs = '6'"
                                        :class="(indicatorsTabs == '6') ? 'active' : ''"
                                        class="pointer d-flex align-items-center justify-content-between">
                                        <span class="rounded-circle"><img src="assets/images/indicator.svg" alt="global"
                                                title="GDP"></span>
                                        <span class="d-flex align-items-center justify-content-between clickView"> Trade
                                            <vue-feather size="18px" type="chevron-down"></vue-feather> </span>
                                    </h6>
                                    <ul class="openAccordian" :class="(indicatorsTabs == '6') ? 'show' : ''">
                                        <li><a class="active" href="javascript:void(0)">GDP</a></li>
                                        <li><a href="javascript:void(0)">GNP</a></li>
                                        <li><a href="javascript:void(0)">GDP Constant Rate </a></li>
                                        <li><a href="javascript:void(0)">GDP Fixed Capital Formation</a></li>
                                    </ul>
                                </div>
                                <div class="accordianList">
                                    <h6 @click.prevent="indicatorsTabs = '7'"
                                        :class="(indicatorsTabs == '7') ? 'active' : ''"
                                        class="pointer d-flex align-items-center justify-content-between">
                                        <span class="rounded-circle"><img src="assets/images/business.svg" alt="global"
                                                title="GDP"></span>
                                        <span class="d-flex align-items-center justify-content-between clickView">
                                            Business <vue-feather size="18px" type="chevron-down"></vue-feather></span>
                                    </h6>
                                    <ul class="openAccordian" :class="(indicatorsTabs == '7') ? 'show' : ''">
                                        <li><a class="active" href="javascript:void(0)">GDP</a></li>
                                        <li><a href="javascript:void(0)">GNP</a></li>
                                        <li><a href="javascript:void(0)">GDP Constant Rate </a></li>
                                        <li><a href="javascript:void(0)">GDP Fixed Capital Formation</a></li>
                                    </ul>
                                </div>
                                <div class="accordianList">
                                    <h6 @click.prevent="indicatorsTabs = '8'"
                                        :class="(indicatorsTabs == '8') ? 'active' : ''"
                                        class="pointer d-flex align-items-center justify-content-between">
                                        <span class="rounded-circle"><img src="assets/images/government.svg"
                                                alt="global" title="GDP"></span>
                                        <span
                                            class="d-flex align-items-center justify-content-between clickView">Government
                                            <vue-feather size="18px" type="chevron-down"></vue-feather></span>
                                    </h6>
                                    <ul class="openAccordian" :class="(indicatorsTabs == '8') ? 'show' : ''">
                                        <li><a class="active" href="javascript:void(0)">GDP</a></li>
                                        <li><a href="javascript:void(0)">GNP</a></li>
                                        <li><a href="javascript:void(0)">GDP Constant Rate </a></li>
                                        <li><a href="javascript:void(0)">GDP Fixed Capital Formation</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </div>
    </div>
</template>

<script>
import { useMainStore } from "@/store";
import 'vue3-carousel/dist/carousel.css';
import { Carousel, Slide, Navigation } from 'vue3-carousel';
import bannerslider from "../../components/shared/banner_slider";
// import bottomnavbar from "../economic-overview/files/bottom_navbar";
import * as am5 from "@amcharts/amcharts5";
import * as index from "@amcharts/amcharts5/index";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5percent from "@amcharts/amcharts5/percent";
import moment from "moment";
export default {
    setup() {
    const store = useMainStore();
    return { store };
  },
    data() {
        return {
            grossTab: 'Forecast',
            indicatorsTabs:'1',
            Compare: false,
            summery: false,
            countryIndicator: false,
            CompareDrop: false,
            CompareDrop1: false,
            dropdownMenu: false,
            taskTab: 0,
            settings: {
                itemsToShow: 1,
                snapAlign: 'center',
            },
            breakpoints: {
                575: {
                    itemsToShow: 2,
                    snapAlign: 'center',
                },
                1070: {
                    itemsToShow: 3,
                    snapAlign: 'center',
                },
            },
            indicators : ['GDP'],
            barChartData : [],
            chartData : [],
            showChart : 'Bar',
            filteryear : '',
            compareIndicators : [],
            compareCountries : []

        }
    },
    watch : {
        countryIndicator(){
            if(this.countryIndicator){
                this.compareCountries = []
                this.compareCountries.push({'country' : 'United States'},{'country' : 'China'})
            }else{
                this.compareIndicators = []
            }
        }
    },
    methods: {
        addCountry(){
            this.compareCountries.push({'country':'China'})
        },
        removeCountry(item){
            // this.compareCountries = this.compareCountries.filter( val => val.country != item.country)
             this.compareCountries.splice(this.compareCountries.indexOf(item), 1);
        },
        removeIndicator(item){
            if(this.compareIndicators.length){
                this.compareIndicators.splice(this.compareIndicators.indexOf(item), 1);
            }
        },
        drawLineChart(){
            this.barChartData = [];
            if(Object.keys(this.store.economicGdpHistory).length > 0){
                for(let key in this.store.economicGdpHistory[this.$route.query.country]){
                    this.barChartData.push({'date' : this.store.economicGdpHistory[this.$route.query.country][key]['GDP'][0].DateTime,'value':this.store.economicGdpHistory[this.$route.query.country][key]['GDP'][0].Value})
                }
            }
            let d = am5.registry.rootElements.find((i) => i.dom && i.dom.id === 'barChart');
            d?.dispose();
            var root = am5.Root.new("barChart");
            root.interfaceColors.set("grid", am5.color(0xffffff));
            root.setThemes([
              am5themes_Animated.new(root)
            ]);
            var chart = root.container.children.push(am5xy.XYChart.new(root, {
              panX: false,
              panY: false,
              wheelX: "none",
              wheelY: "none",
              pinchZoomX:false
            }));
            var cursor = chart.set("cursor", am5xy.XYCursor.new(root, {
              behavior: "none"
            }));
            cursor.lineY.set("visible", false);
            var xAxis = chart.xAxes.push(am5xy.DateAxis.new(root, {
              maxDeviation: 0.2,
              baseInterval: {
                timeUnit: "day",
                count: 1
              },
              renderer: am5xy.AxisRendererX.new(root, {}),
              tooltip: am5.Tooltip.new(root, {})
            }));

            var yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
              renderer: am5xy.AxisRendererY.new(root, {})
            }));
            var series = chart.series.push(am5xy.LineSeries.new(root, {
              name: "Series",
              xAxis: xAxis,
              yAxis: yAxis,
              valueYField: "value",
              valueXField: "date",
              tooltip: am5.Tooltip.new(root, {
                labelText: "{value}"
              })
            }));
            series.data.processor = am5.DataProcessor.new(root, {
                dateFormat: "yyyy-MM-dd",
                dateFields: ["date"]
            });
            series.data.setAll(this.barChartData);
            chart.zoomOutButton.set("forceHidden", true);
            series.appear(1000);
            chart.appear(1000, 100);
        },
        drawBarChart(){
            this.barChartData = [];
            if(Object.keys(this.store.economicGdpHistory).length > 0){
                for(let key in this.store.economicGdpHistory[this.$route.query.country]){
                    this.barChartData.push({'year' : key,'value':this.store.economicGdpHistory[this.$route.query.country][key]['GDP'][0].Value})
                }
            }
          let d = am5.registry.rootElements.find((i) => i.dom && i.dom.id === 'barChart');
          d?.dispose();
          var root = am5.Root.new("barChart");
          root.setThemes([
            am5themes_Animated.new(root)
          ]);
          root.interfaceColors.set("grid", am5.color(0xffffff));
          var chart = root.container.children.push(am5xy.XYChart.new(root, {
            panX: false,
            panY: false,
            wheelX: "none",
            wheelY: "none"
          }));
          var cursor = chart.set("cursor", am5xy.XYCursor.new(root, {}));
          cursor.lineY.set("visible", false);
          var xRenderer = am5xy.AxisRendererX.new(root, { minGridDistance: 30 });
          xRenderer.labels.template.setAll({
            rotation: 0,
            centerY: am5.p50,
            centerX: am5.p100,
            paddingRight: 15,
            opacity : 0.5,
            fontSize: 12,
          });

          var xAxis = chart.xAxes.push(am5xy.CategoryAxis.new(root, {
            maxDeviation: 0.3,
            categoryField: "year",
            renderer: xRenderer,
            opacity : 0.5,
            fontSize: 12,
            tooltip: am5.Tooltip.new(root, {})
          }));

          var yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
            maxDeviation: 0.3,
            renderer: am5xy.AxisRendererY.new(root, {})
          }));
          var series = chart.series.push(am5xy.ColumnSeries.new(root, {
            name: "Series 1",
            xAxis: xAxis,
            yAxis: yAxis,
            valueYField: "value",
            sequencedInterpolation: true,
            categoryXField: "year",
            tooltip: am5.Tooltip.new(root, {
              labelText:"{valueY}",
               opacity : 0.5,
              fontSize: 12,
            })
          }));

          series.columns.template.setAll({ cornerRadiusTL: 5, cornerRadiusTR: 5 });
          series.set("fill", '#19885E');
          // Set data
            xAxis.data.setAll(this.barChartData);
            series.data.setAll(this.barChartData);
          if(this.barChartData.length){
            const _that = this;
            series.events.once("datavalidated", function(ev, target) {
                let lastData = _that.barChartData.slice(_that.barChartData.length-10,_that.barChartData.length)
                xAxis.zoomToCategories(lastData[0].year,lastData[lastData.length-1].year)
            })

           }
           chart.zoomOutButton.set("forceHidden", true);
            series.appear(1000);
            chart.appear(1000, 100);
        },
        drawPieChart() {
          var sum = 0;
          this.chartData = [];
          if (Object.keys(this.store.economicGDPData).length > 0) {
            for (let key in this.store.economicGDPData) {
              for (let innerkey in this.store.economicGDPData[key]) {
                this.store.economicGDPData[key][innerkey].forEach((item) => {
                  if (item.Category.includes("GDP from")) {
                    sum += parseFloat(item.LatestValue);
                  }
                });
                this.store.economicGDPData[key][innerkey].forEach((val) => {
                  if (val.Category.includes("GDP from")) {
                    let percent = parseFloat(
                      (parseFloat(val.LatestValue) / sum) * 100
                    ).toFixed(2);
                    this.chartData.push({
                      sector: val.Category,
                      size: val.LatestValue,
                      percent: percent,
                    });
                  }
                });
              }
            }
          }
          if(this.chartData.length){
                this.chartData = this.chartData.sort(function (a, b) {
                    return b.percent - a.percent;
                });
            }
          let d = am5.registry.rootElements.find(
            (i) => i.dom && i.dom.id === "compChart"
          );
          d?.dispose();
          var root = am5.Root.new("compChart");
          root.setThemes([am5themes_Animated.new(root)]);
          var chart = root.container.children.push(
            am5percent.PieChart.new(root, {
              innerRadius: am5.percent(50),
              layout: root.verticalLayout,
            })
          );

          var series = chart.series.push(
            am5percent.PieSeries.new(root, {
              valueField: "size",
              alignLabels: false,
              categoryField: "sector",
            })
          );
          // chart.get("colors").set("colors", [
          //   '#19885E',
          //   '#1D9C6C',
          //   '#20AC77',
          //   '#1DBD81',
          //   '#16C784',
          //   '#2ED696'
          //   ]);

          series.labels.template.setAll({
            fontSize: 12,
            text: "{percent}%",
            textType: "circular",
            inside: true,
            radius: 10,
            fill: am5.color(0xffffff),
          });
          series.data.setAll(this.chartData);
          series.slices.template.set("toggleKey", "none");
          series.appear(1000, 100);
        },
        drawMultiLineChart(){
            if(this.countryIndicator){
                let d = am5.registry.rootElements.find(
                (i) => i.dom && i.dom.id === "compareCountry"
               );
               d?.dispose();
                var root = am5.Root.new("compareCountry");
            }else{
                let d = am5.registry.rootElements.find(
                (i) => i.dom && i.dom.id === "compareIndicator"
               );
               d?.dispose();
                var root = am5.Root.new("compareIndicator");

            }
            root.interfaceColors.set("grid", am5.color(0xffffff));
            root.setThemes([
              am5themes_Animated.new(root)
            ]);
            root.setThemes([
              am5themes_Animated.new(root)
            ]);
            var chart = root.container.children.push(
              am5xy.XYChart.new(root, {
                focusable: true,
                panX: true,
                panY: true,
                wheelX: "panX",
                wheelY: "zoomX",
              pinchZoomX:false
              })
            );

            var easing = am5.ease.linear;
            chart.get("colors").set("step", 3);
            var xAxis = chart.xAxes.push(
              am5xy.DateAxis.new(root, {
                maxDeviation: 0.1,
                groupData: false,
                baseInterval: {
                  timeUnit: "year",
                  count: 1
                },
                renderer: am5xy.AxisRendererX.new(root, {}),
                tooltip: am5.Tooltip.new(root, {})
              })
            );
            function createAxisAndSeries(val, opposite) {
              var yRenderer = am5xy.AxisRendererY.new(root, {
                opposite: opposite
              });
              var yAxis = chart.yAxes.push(
                am5xy.ValueAxis.new(root, {
                    visible: false,
                   maxDeviation: 1,
                   renderer: yRenderer
                })
              );

              if (chart.yAxes.indexOf(yAxis) > 0) {
                yAxis.set("syncWithAxis", chart.yAxes.getIndex(0));
              }

              // Add series
              // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
              var series = chart.series.push(
                am5xy.LineSeries.new(root, {
                  xAxis: xAxis,
                  yAxis: yAxis,
                  valueYField: "value",
                  valueXField: "date",
                  tooltip: am5.Tooltip.new(root, {
                    pointerOrientation: "horizontal",
                    labelText: "{key}:{valueY}"
                  })
                })
              );

              //series.fills.template.setAll({ fillOpacity: 0.2, visible: true });
              series.strokes.template.setAll({ strokeWidth: 1 });

              yRenderer.grid.template.set("strokeOpacity", 0.05);
              yRenderer.labels.template.set("fill", series.get("fill"));
              yRenderer.setAll({
                stroke: series.get("fill"),
                strokeOpacity: 1,
                opacity: 1
              });

              series.data.processor = am5.DataProcessor.new(root, {
                dateFormat: "yyyy-MM-dd",
                dateFields: ["date"]
              });
              series.data.setAll(val);
            }

            var cursor = chart.set("cursor", am5xy.XYCursor.new(root, {
              xAxis: xAxis,
              behavior: "none"
            }));
            cursor.lineY.set("visible", false);
            let currentYear = moment().format('YYYY');
            let tenYearsBack = moment().subtract(10,'years').format('YYYY')
            if(this.countryIndicator){//compare countries
                for(let key in this.store.economicGdpHistory){
                   let data = [];
                    for(let innerkey in this.store.economicGdpHistory[key]){
                        if(parseInt(innerkey) >= parseInt(tenYearsBack) && parseInt(innerkey) <= parseInt(currentYear)){
                            data.push({'key' : key,'date' :this.store.economicGdpHistory[key][innerkey]['GDP'][0].DateTime.split('T')[0],'value':this.store.economicGdpHistory[key][innerkey]['GDP'][0].Value})
                        }
                    }
                    createAxisAndSeries(data, true);
                }
            }else{//compare indicators
                for (let indkey in this.static_vars.economyOverviewIndicators) {
                    let data1 = [];
                    for(let key in this.store.economicGdpHistory[this.$route.query.country]){
                        if(parseInt(key) >= parseInt(tenYearsBack) && parseInt(key) <= parseInt(currentYear)){
                            for(let innerkey in this.store.economicGdpHistory[this.$route.query.country][key]){
                                if(innerkey == this.static_vars.economyOverviewIndicators[indkey]){
                                    data1.push({'key' : indkey,'date' :this.store.economicGdpHistory[this.$route.query.country][key][innerkey][0].DateTime.split('T')[0],'value':this.store.economicGdpHistory[this.$route.query.country][key][innerkey][0].Value})
                                }
                            }
                        }
                    }

                    createAxisAndSeries(data1, true);
                }
            }
            chart.appear(1000, 100);
        },
        checkIndicators() {
            this.Compare = false
            if (this.countryIndicator) {
                this.taskTab = 2
            } else {
                this.taskTab = 1
            }
            this.callCountryEconomyData(this.$route.query.country)
        },
        callCountryEconomyData(country){
        let formData = {
            country: [country],
            indicator: this.indicators,
          };
          if(this.filteryear != ''){
            formData['year'] = parseInt(this.filteryear)
          }
          if(this.compareIndicators.length && !this.countryIndicator){
            let data = [];
            if(!this.compareIndicators.includes('GDP')){
                data = this.compareIndicators
               data = data.concat(['GDP'])
            }
            formData['indicator'] = data
          }
          if(this.countryIndicator){
            this.compareIndicators = []
            let data = this.compareCountries.map(val => val.country)
            data = data.concat([country])
            data = [...new Set(data)]
            formData['country'] = data
            formData['indicator'] = ['GDP']
          }
          this.store
            .getCountryEconomyHistory(formData, true)
            .then((response) => {
                if(Object.keys(response).length > 0){
                    am5.ready(() => {
                        if(this.compareIndicators.length || this.countryIndicator){
                            this.drawMultiLineChart()
                        }else{
                            if(this.showChart == 'Bar'){
                                this.drawBarChart();
                            }else{
                                this.drawLineChart();
                            }
                        }
                    });
                }
            })
            .catch((err) => console.log(err.message));
        },
    },
    computed: {
        allCountries() {
            let arr = [];
            for (let key in this.static_vars.economicCalendarCountries) {
                this.static_vars.economicCalendarCountries[key].map((i) => {
                    arr.push(i);
                });
            }
            return [...new Set(arr)].sort((a, b) => a.localeCompare(b));
        },
    },
    mounted() {
         am5.ready(() => {
            this.drawPieChart()
         });
        const settings = {
            fill: '#1abc9c',
            background: '#d7dcdf'
        }
        const sliders = document.querySelectorAll('.range-slider');
        Array.prototype.forEach.call(sliders, (slider) => {
            slider.querySelector('input').addEventListener('input', (event) => {
                slider.querySelector('.slider__value').innerHTML = event.target.value;
                applyFill(event.target);
            });
            applyFill(slider.querySelector('input'));
        });
        function applyFill(slider) {
            const percentage = 100 * (slider.value - slider.min) / (slider.max - slider.min);
            const bg = `linear-gradient(90deg, ${settings.fill} ${percentage}%, ${settings.background} ${percentage + 0.1}%)`;
            slider.style.background = bg;
        }

    },
    components: {
        Carousel,
        Slide,
        Navigation,
        bannerslider
    },
    created(){
        if (this.$route.query.country) {
            this.callCountryEconomyData(this.$route.query.country);
        } 
        // this.compareCountries.push({'country' : 'United States'},{'country' : 'China'})
    }
}
</script>
